@tailwind base;
@tailwind components;
@tailwind utilities;

/**** FIGMA!!!! ****/
body * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: never;
}
body {
  overflow-x: hidden !important;
}

.toggle-sign {
  right:0;
  margin-top: 12px;
  position: absolute;
}
.accordion-content {
  padding-top: 12px;
}

.small-video {
  max-width: none;
  width: 100% !important;
  object-fit: cover;
}

.small-video video {
  max-width: none;
  width: 100% !important;
  object-fit: cover;
}
  
.off-screen {
    transform: translateX(-200vw); /* Move off-screen to the left */
    transition: transform 0s 0.5s; /* Apply delay to the transform */
}

.on-screen {
    transform: translateX(0); /* Move back to the original position */
    transition: transform 0s 0s; /* No delay */
}

.transition-js-opacity {
    transition: opacity 0.5s; /* Only transition opacity */
}

.detail h6:first-of-type {
    margin-top: 30px !important;
    border: none !important;
}

.detail p:first-of-type {
    margin: 0px !important;
    padding: 0px !important;
}

.react-calendar__navigation {
  text-align: center;
}
.react-calendar__navigation button {
  display: inline-block;
  margin: 8px;
  font-size: 20px;
}

.react-calendar__month-view__weekdays{
  text-align: center;
  margin: 20px 0;
}

.react-calendar__tile {
  height: 60px;
}

.react-calendar__month-view__days__day--weekend {
    color: #383635;
}

.react-calendar__tile--active:enabled:focus {
    background: #383635;
    color: #F4EFE7;
}
.react-calendar__tile--rangeEnd:enabled {
    background: #383635;
    color: #F4EFE7;
}

.react-calendar__tile--rangeStart:enabled {
    background: #827C78;
    color: #F4EFE7;
}

.react-calendar__tile:enabled:hover {
    background: #383635;
    color: #F4EFE7;
}



.react-calendar__tile--active {
    background: #827C78;
}

.react-calendar__tile--range,
.react-calendar__tile--range:enabled:hover {
    color: #383635;
    background: #E8E2DC;
}

.react-calendar__tile--now {
    color: #383635;
    background: #E8E2DC;
}

.pac-container {
  z-index: 20000 !important;
}


/* .home .video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.videoPromo.video-wrapper {
  position: relative;
  width: 100%;
  height: 100vh; 
  overflow: hidden; 
  background-color: black; 
}

.videoPromo .video-player {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 177.78%; 
  height: 100%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

@media (max-aspect-ratio: 1/1) {
  .video-player {
    width: 100%;
    height: 177.78%; 
  }
} */

.home .video-wrapper {
  position: relative;
  width: 100vw; /* Full width of the viewport */
  height: 100vh; /* Full height of the viewport */
  overflow: hidden; /* Crop the overflow */
}

.home .video-player {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the video */
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  object-fit: cover; /* Ensure the video covers the entire container */
}

.videoPromo.video-wrapper {
  position: relative;
  width: 100vw; /* Full width of the viewport */
  height: 100vh; /* Full height of the viewport */
  overflow: hidden; /* Crop the overflow */
}

.videoPromo .video-player {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the video */
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  object-fit: cover; /* Ensure the video covers the entire container */
}

input:-webkit-autofill {
  animation-name: autofill;
  animation-duration: 0.1s;
  animation-fill-mode: both;
}

@keyframes autofill {
  from { }
  to { }
}

.scrollable-div {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* Enables smooth scrolling on iOS */
  height: 100%; /* Or another height constraint */
}